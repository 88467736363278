import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { withPrefix } from "gatsby"

import { P } from "components/Typography"
import Dialog from "components/Layouts/Dialog"
import FeaturedHeader from "components/Blog/FeaturedHeader"
import { GalleryList } from "components/Media/MediaGallery"



const EmbedContainer = styled.div`
    width: 100%;
    position: relative;
`

const IframeContainer = styled.div`
    position: relative;
    height:0;
    width: 100%;
    padding-bottom: 56.36%;

    iframe {
        position: absolute;
        top:0;left:0;
        height:100%;
        width: 100%;
    }
`

export const StyledYtPost = styled.div`
    width: calc(33.33% - 1rem);
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    transition: 0.3s ease-out opacity;
    margin-bottom: 2.456rem;
    cursor: pointer;


    &:hover {
        opacity: 0.6;
    }


    @media (max-width: 768px) {
        width: 100%;
    }



    figure {
        position: relative;
        height: 0;
        margin-bottom: 0.5rem;
        width: 100%;
        padding-bottom: 57%;
    }
    
    a, button {
        display: block;
        width:100%;
    }


    .preview-img {
        position: absolute;
        top: 0;left:0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .play-btn {
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        width: 4rem;
        height: 4rem;
        z-index: 4;
        transform-origin: center;
    }
`

const YtPost = ({ snippet }) => {
    const [videoOpen, setVideoOpen] = useState(null)

    if (!snippet.thumbnails.standard) return null

    return (
        <>
            <Dialog
                showDialog={videoOpen}
                close={() => setVideoOpen(false)}
                open={() => setVideoOpen(true)}
            >
                <EmbedContainer>
                    <IframeContainer>
                        <iframe title={snippet.resourceId.videoId} id="ytplayer" type="text/html"
                            src={`https://www.youtube.com/embed/${snippet.resourceId.videoId}?autoplay=1`}
                            frameborder="0" />
                    </IframeContainer>
                </EmbedContainer>
            </Dialog>

            <StyledYtPost>
                <button onClick={() => setVideoOpen(true)} target="_blank" rel="noopener noreferrer">
                    <figure>
                        <img alt="" className="preview-img" src={snippet.thumbnails.standard.url} />
                        <img className="play-btn" src={withPrefix(`/images/play.svg`)} alt="Play Button" />
                    </figure>
                    <P style={{
                        fontSize: "1rem",
                        // textTransform: "uppercase"
                    }}>{snippet.title}</P>
                </button>
            </StyledYtPost>
        </>
    )
}

const Youtube = ({ account, url, title }) => {
    const [posts, setPosts] = useState([])
    // const [loading, setLoading] = useState(false)


    useEffect(() => {
        const getPosts = async () => {
            // setLoading(true)

            const request = await fetch(`https://sky-utils.vercel.app/api/yt`)
            const json = await request.json()

            if (json.items) {
                setPosts(json.items)
            }

            // setLoading(false)
        }

        getPosts()
    }, [])

    return (
        <div>
            <FeaturedHeader>Videos</FeaturedHeader>
            <GalleryList
                style={{
                    justifyContent: "flex-start"
                }}
            >
                {posts && posts.map(post => <YtPost key={post.id} {...post} />)}
            </GalleryList>
        </div>

    )
}

export default Youtube
