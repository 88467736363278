import React from "react"
import { Dialog as ReachDialog } from "@reach/dialog";
import styled from "styled-components"

import "@reach/dialog/styles.css";

import { Close } from "components/MobileHeader/Icons"

const DialogBackground = styled.button`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 2;
`

const DialogContent = styled.div`
    width: 100%;
    background-color: var(--background);
    border:1px solid var(--lilacLowOpacity);
    max-width: ${({ small }) => small ? "45rem" : "60rem"};
    width: 100%;
    z-index: 3;
    position: relative;

    img {
        width: 100%;
        vertical-align: middle;
    }
`
const CloseButton = styled.button`
    position: absolute;
    top: 0;
    right: -3rem;
    width: 2.5rem;
    height: 2.5rem;
    z-index: 3;

    @media (max-width: 768px) {
        right: 0;
        top: -3rem;
    }

    svg {
        fill: white;
    }
`

const Dialog = ({
    showDialog, open, close, children, small = false
}) => {
    return (
        <div>
            <ReachDialog
                aria-label="Dialog"

                isOpen={showDialog} onDismiss={close}
            >
                <>
                    <DialogBackground onClick={close} />
                    <DialogContent small={small}>
                        <div>{children}</div>
                        <CloseButton onClick={close}>
                            <Close />
                        </CloseButton>
                    </DialogContent>
                </>
            </ReachDialog>
        </div>
    )
}

export default Dialog
