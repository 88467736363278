import React from "react";

import "../../css/main.css";
import "../../css/fonts.css";
import "../../css/reset.css";

// import MediaPage from "../../components/Media";
import Seo from "../../components/SeoHead";
import Layout from "../../components/Blog/Layout"
import Youtube from "../../components/Community/SocialFeed/Youtube";

const Wallpapers = () => {
	return (
		<>
			<Seo />
			<Layout>
				<Youtube
					title="Videos"
					account={`Horizon Blockchain Games`}
					url={`https://www.youtube.com/channel/UClFG7LMrK6icGVyCpNDJlVQ`} />
			</Layout>
		</>
	);
};


export default Wallpapers
